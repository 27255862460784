export const useInfiniteSroll = (cb: () => void, threshold = 0.8) => {
	let ticking = false;
	function scrollHandler() {
		if (!ticking) {
			window.requestAnimationFrame(function () {
				const scrollTop = window.scrollY || document.documentElement.scrollTop;
				const scrollHeight = document.documentElement.scrollHeight;
				const clientHeight = document.documentElement.clientHeight;
				const scrollPercentage = scrollTop / (scrollHeight - clientHeight);

				if (scrollPercentage >= threshold) {
					cb();
				}

				ticking = false;
			});

			ticking = true;
		}
	}

	onMounted(() => {
		// if scroll height is smaller than client height, we don't need to listen for scroll
		if (
			document.documentElement.clientHeight <=
			document.documentElement.scrollHeight
		) {
			cb();
		}
		window.addEventListener('scroll', scrollHandler);
	});

	onUnmounted(() => {
		window.removeEventListener('scroll', scrollHandler);
	});
};
