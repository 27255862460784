<template>
	<svg
		width="172"
		height="128"
		viewBox="0 0 172 128"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="20" cy="20" r="6" fill="#F5F5F5" />
		<circle cx="17" cy="112" r="8" fill="#F5F5F5" />
		<circle cx="160" cy="36" r="8" fill="#F5F5F5" />
		<circle cx="149" cy="9" r="5" fill="#F5F5F5" />
		<g filter="url(#filter0_b_3094_5232)">
			<rect
				x="74.2012"
				y="25.7072"
				width="56"
				height="56"
				rx="12"
				transform="rotate(15 74.2012 25.7072)"
				fill="#525252"
			/>
			<g filter="url(#filter1_b_3094_5232)">
				<rect
					x="56"
					y="42"
					width="56"
					height="56"
					rx="12"
					fill="white"
					fill-opacity="0.6"
				/>
				<rect
					x="56.5"
					y="42.5"
					width="55"
					height="55"
					rx="11.5"
					stroke="white"
					stroke-opacity="0.6"
				/>
				<path
					d="M94.5 80.5L90.4168 76.4167M93.3333 69.4167C93.3333 74.8935 88.8935 79.3333 83.4167 79.3333C77.9398 79.3333 73.5 74.8935 73.5 69.4167C73.5 63.9398 77.9398 59.5 83.4167 59.5C88.8935 59.5 93.3333 63.9398 93.3333 69.4167Z"
					stroke="white"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</g>
		<defs>
			<filter
				id="filter0_b_3094_5232"
				x="48"
				y="17.7072"
				width="88.293"
				height="88.2928"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
				<feComposite
					in2="SourceAlpha"
					operator="in"
					result="effect1_backgroundBlur_3094_5232"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_backgroundBlur_3094_5232"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_b_3094_5232"
				x="40"
				y="26"
				width="88"
				height="88"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
				<feComposite
					in2="SourceAlpha"
					operator="in"
					result="effect1_backgroundBlur_3094_5232"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_backgroundBlur_3094_5232"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
</template>
